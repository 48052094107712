import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'

import GrowManager from "../assets/images/grow-manager.png";
import CalendlyLogo from "../assets/images/calendly-blue.png";

export default function() {
    
    return (
        <div className="cta-meet">
            <div className="cta-meet-container"> 
                <Row>
                    <Col xs={12} md={12} lg={6} className="cta-text">
                        <h1 className="cta-title">Book a meeting</h1>
                        <p className="cta-description">Join Yllza, our growth manager, for a 30-minute session where she will demonstrate how Sogody can help your brand unlock new digital capabilities.</p>
                        <a className="primary-btn-s meet-sogody" href="https://calendly.com/yllzakelmendi/30min" target="_blank">Set up a meeting <img src={CalendlyLogo} alt="calendly logo" className="calendly-img" /></a>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="cta-img">
                        <img src={GrowManager} alt="grow manager" />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

