import React from 'react'
import CompanyStaff from '../components/CompanyStaff'
import CtaMeet from '../components/CtaMeet'
import ClientsSecond from '../components/ClientsSecond'
import Layout from "../components/Layout"
import {Container } from 'react-bootstrap'
import CompanyBoxes from '../components/CompanyBoxes'
import CareerCarousel from '../components/CareerCarousel';
import ReactPlayer from 'react-player'
// import myVideo from 'https://assets.sogody.co.uk/sogody/showcase-video.mp4'
import { Seo } from '../components/Seo'
import BannerCompany from '../components/BannerCompany'
import { useLocation } from "@reach/router"


export default function() {
    // const [videoLoaded, setVideoLoaded] = useState(false);
    const pathLocation = useLocation();
    const url = pathLocation.href;
    return (
    <Layout>  
        <Seo 
            title="Company - Sogody" 
            description="Our determination to deliver every service with high quality is an element embedded deep down in our company culture and professional character that we tend to follow endlessly."
            url={url}
        />
        <div className="company"> 
        <BannerCompany/>
            <Container>
                {/* { !videoLoaded &&  <img zstyle={{maxHeight: "540px", maxWidth:"100%"}}  src={'https://assets.sogody.co.uk/sogody/company-poster.jpg'} alt="Company"  />  }  */}
                <ReactPlayer
                    className='react-player'
                    url= 'https://assets.sogody.co.uk/sogody/showcase-video.mp4'
                    width='100%'
                    height='100%'
                    playing={true}
                    volume={1}
                    muted={true}
                    controls = {true}
                    loop={false}
                />
            </Container>
            <CompanyBoxes/>
            <CompanyStaff/>  
            <Container>  
            <CtaMeet/> 
            </Container>
            <CareerCarousel/>  
            <ClientsSecond/> 
        </div>
    </Layout>
    )
}


