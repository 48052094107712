import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import {BLOCKS, MARKS} from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Row, Col} from "react-bootstrap";


const ComponentName = () => {
    
    const data = useStaticQuery(query);
    const {allContentfulSogodyIntroductionBox:{
        nodes:sb
    }} = data
    
    const options={
        renderNode:{

        [BLOCKS.HEADING_1]:(node, children) =>
        (<h1 className="heading1">{children}</h1>),

        [BLOCKS.HEADING_2]:(node, children) =>
        (<h2 className="heading2">{children}</h2>),

        [BLOCKS.HEADING_3]:(node, children) =>
        (<h3 className="heading3">{children}</h3>),

        [BLOCKS.HEADING_4]:(node, children) =>
        (<h4 className="heading4">{children}</h4>),

        [BLOCKS.HEADING_5]:(node, children) =>
        (<h5 className="heading5">{children}</h5>),

        [BLOCKS.HEADING_6]:(node, children) =>
        (<h6 className="heading6">{children}</h6>),

        [BLOCKS.UL_LIST]:(node, children) =>
        (<ul className="ul-list">{children}</ul>),

        [BLOCKS.OL_LIST]:(node, children) =>
        (<ol className="ol-list">{children}</ol>),
        
        [BLOCKS.EMBEDDED_ASSET]:(node, children) =>
        (<img className="sb-image" alt="sb img" src={`https:${node.data.target.fields.file["en-US"].url}`}/>),

        [BLOCKS.PARAGRAPH]:(node, children) =>
        (<p className="sb-paragraph">{children}</p>),
        },

        renderMark: {
            [MARKS.CODE]: code => <code>{code}</code>,
            [MARKS.BOLD]: text => <b>{`${text}`} </b>,
        },
    }


return <div className="sogody-box">
            {sb.map((sb)=>{
                return(
                <div className="box-content">
                    <Row>
                        <Col xs="12" md="6" lg="5" style={{margin:"auto"}}>
                            <h2 className="title">{sb.title}</h2>
                            <div className="sogody-box-text">
                                {documentToReactComponents(sb.content.json, options)
                                    && (
                                        <p>{documentToReactComponents(sb.content.json, options)}</p>
                                    )
                                }
                            </div> 
                        </Col>
                        <Col xs="12" md="6" lg="7" >
                            <div style={{height:"100%"}} className="sog-box-img">
                                <img src={sb.image.fluid.src}  alt="Sogody box" style={{ maxWidth: "100%", maxHeight: "100%", minHeight: "100%", objectFit: "cover"  }}/>
                            </div>
                    
                            {console.log(sb.image.src)}
                            {/* <Image className="" fluid={sb.image.fluid} alt="Cta img"></Image> */}
                        </Col>
                    </Row>
                </div>
                )})}
</div>
}

export const query = graphql`
{
    allContentfulSogodyIntroductionBox(filter: {node_locale: {eq: "en-US"}}, limit: 1) {
        nodes {
            title
            image {
                fluid(quality: 100) {
                src
                }
            }
            content {
                json
            }
        }
    }
}
`

export default ComponentName

