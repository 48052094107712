import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';
import { Row, Container, Col} from 'react-bootstrap';

export default function() {
    const data = useStaticQuery(query);
    const {allContentfulClients:{
        nodes:clients
    }} = data

    return (
        <div className="clients-second">  
            <Container> 
            <Row> 
                <Col md="12">
                <div className="client-title"> 
                    <p>Trusted by many:</p>
                </div>
                </Col>
            </Row>
            <Row className="client-row">
                {clients.map((clients)=>{
                    return <>
                    <Col md={3} sm={3} lg="2" xs={4} style={{ marginBottom:"30px",  display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <img className="client-img-s"  style={{ width: "100%", height: "100%", maxHeight: "65px"}} src={clients.clientLogo.fluid.src} alt="Logo" />
                    </Col>
                    </> 
                })}
            </Row> 
            </Container></div>
    )
}

export const query = graphql`
{
    allContentfulClients(filter: {node_locale: {eq: "en-US"}}, sort: {fields: updatedAt}) {
        nodes {
            clientLogo {
                fluid(quality: 100) {
                    src
                }
            }
        }
    }
}
`

