import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';

export default function() {
    const data = useStaticQuery(query);
    const {allContentfulSogodysCompanyIntro:{
        nodes:si
    }} = data

    return (
        <div className="sogody-text">  
            
                {si.map((si)=>{
                    return <>
                        <p className="title">{si.title.title} </p>
                    </> 
                })}
        </div>
    )
}

export const query = graphql`
{
    allContentfulSogodysCompanyIntro(filter: {node_locale: {eq: "en-US"}}) {
        nodes {
            title {
                title
            }
        }
    }
}
`

