import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function() {
    const data = useStaticQuery(query);
    const {allContentfulCareerImagesCarousel:{
        nodes:lap
    }} = data

    const settings = {
        className: "center",
        dots:false,
        centerMode: true,
        infinite: true,
        autoplay:true,
        speed: 500,
        // centerPadding: "150px",
        slidesToShow: 1,
        slidesToScroll: 1,
    };

return ( 
    <div className="career-carousel"> 
            <Slider {...settings}>
                {lap.map((lap)=>{
                return <div> 
                    {/* <Image className="cta-img-abs" style={{ width: "100%", height: "100%", maxHeight:"700px" }} fluid={lap.image.fluid} alt="Cta img"></Image> */}
                    <img className="career-img" src={lap.image.file.url} alt="Logo" />
                </div>
                })}
            </Slider>
    </div>  
    )
}

export const query = graphql`
{
    allContentfulCareerImagesCarousel( filter: {node_locale: {eq: "en-US"}}, sort: {fields: updatedAt}) {
        nodes {
            image {
                file {
                    url
                }
            }
        }
    }
}
`


